import React from 'react';
import SetPinModule from './SetPinModule';
import { changePasswordEndpoint, errorUiEndpoint, loginUiEndpoint } from '../utils/endpoints';
import amazonLogo from '../amazon_logo.svg';
import '../css/App.css';

class ChangePasswordPage extends React.Component {
    state = {
        csrfToken: undefined,
    };

    // Using redirect:manual as When following a redirect during a CORS request,
    // if the request is redirected to a URL on a different server, the Origin header is changed to "null".
    componentDidMount() {
        fetch(changePasswordEndpoint.concat(window.location.search), {
            credentials: 'include',
            redirect: 'manual'
        }).then((response) => {
            if (response.type === "opaqueredirect" && response.status === 0) {
                // response will be a 302 redirect in case backend sends an unauthorized or error response. So in both the cases we will redirect to session expired login page.
                // We are handling redirect manually so in this case, response.status = 0 and response.type = "opaqueredirect" indicates redirect when `redirect` is 'manual'.
                window.location.href = loginUiEndpoint
                    .concat(window.location.search)
                    .concat("&error=session-expired");
                return null;
            } else {
                return response.json();
            }
        }).then((data) => {
            this.setState({
                csrfToken: data._csrf,
            });
        }).catch((error) => {
            console.error(error);
            if (window.location.href.includes("localhost")) {
                console.log("localhost testing - not redirecting to error page");
            } else {
                window.location.href = errorUiEndpoint.concat(window.location.search);
            }
        });
    }

    render() {
        return (
            <form method="POST" action={`${changePasswordEndpoint}${window.location.search}`} className="IhmChangePasswordForm">
                <img className="amazon-logo" src={amazonLogo} alt="Amazon Logo" />
                <input name="_csrf" type="hidden" defaultValue={this.state.csrfToken} />
                <SetPinModule />
            </form>
        )
    };
}

export default ChangePasswordPage;

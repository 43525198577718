import React from 'react';
import '../css/App.css';

class SetPinModule extends React.Component<any, any> {
    state = {
        password: '',
        maskedPassword: '',
        confirmPassword: '',
        maskedConfirmPassword: '',
        buttonDisable: true,
        pinClickedDisable: false,
    };

    disableSubmitButton() {
        if ((this.state.password.match(/^[0-9]{6}$/)) && (this.state.password === this.state.confirmPassword) && (this.state.password.match(/^\d+$/))) {
            this.setState({ buttonDisable: false });
        }
        else {
            this.setState({ buttonDisable: true });
        }
    };

    //method to mask the password with "•", since we are not using the type="password" attribute for the PIN input.
    handleChangePassword = (event: any) => {
        if (event.nativeEvent.data) {
            const char = event.nativeEvent.data;
            const isDigit = !isNaN(parseInt(event.nativeEvent.data));

            if (!isDigit || this.state.maskedPassword.length >= 6) {
                event.preventDefault();
                return;
            }

            this.setState({
                maskedPassword: this.state.maskedPassword + "•",
                password: (this.state.password + char),
            }, this.disableSubmitButton);
            event.preventDefault();
        } else {
            this.setState({
                maskedPassword: this.state.maskedPassword.slice(0, this.state.maskedPassword.length - 1),
                password: this.state.password.slice(0, this.state.password.length - 1),
            }, this.disableSubmitButton);
        }
    }

    handleChangeConfirmPassword = (event: any) => {
        if (event.nativeEvent.data) {
            const char = event.nativeEvent.data;
            const isDigit = !isNaN(parseInt(event.nativeEvent.data));

            if (!isDigit || this.state.maskedConfirmPassword.length >= 6) {
                event.preventDefault();
                return;
            }

            this.setState({
                maskedConfirmPassword: this.state.maskedConfirmPassword + "•",
                confirmPassword: (this.state.confirmPassword + char),
            }, this.disableSubmitButton);
            event.preventDefault();
        } else {
            this.setState({
                maskedConfirmPassword: this.state.maskedConfirmPassword.slice(0, this.state.maskedConfirmPassword.length - 1),
                confirmPassword: this.state.confirmPassword.slice(0, this.state.confirmPassword.length - 1),
            }, this.disableSubmitButton);
        }
    }

    handleCopyPaste = (event: any) => {
        event.preventDefault();
    };

    render() {
        return (
            <>
                <h3>Please set your PIN</h3>
                <input className="form-group form-control" type="tel" id="maskedPassword" maxLength={6} placeholder="Enter PIN" autoComplete="off" value={this.state.maskedPassword}
                    onPaste={this.handleCopyPaste} onCopy={this.handleCopyPaste} onChange={this.handleChangePassword} required autoFocus></input>
                <input type="hidden" name="password" value={this.state.password} />
                <input className="form-group form-control" type="tel" id="maskedConfirmPassword" maxLength={6} placeholder="Confirm PIN" autoComplete="off" value={this.state.maskedConfirmPassword}
                    onPaste={this.handleCopyPaste} onCopy={this.handleCopyPaste} onChange={this.handleChangeConfirmPassword} required ></input>
                <input type="hidden" name="confirmPassword" value={this.state.confirmPassword} />
                <div className="input-conditions">
                    {/* Check whether the password contains only numbers */}
                    {this.state.password === "" ? "" :
                        (this.state.password.match(/^\d+$/) ? <p style={{ color: "green" }}> Password should only consist of numbers</p> :
                            <p style={{ color: "red" }}> Password should only consist of numbers</p>
                        )}
                    {/* Check whether the password contains exactly 6 numbers */}
                    {this.state.password === "" ? "" :
                        (this.state.password.match(/^[0-9]{6}$/) ? <p style={{ color: "green" }}> Password should be 6 digit long</p> :
                            <p style={{ color: "red" }}> Password should be 6 digit long </p>
                        )}
                    {/* Check if both passwords match */}
                    {this.state.password === "" ? "" :
                        (this.state.password === this.state.confirmPassword ? <p style={{ color: "green" }}> Passwords must match </p> :
                            <p style={{ color: "red" }}> Passwords must match </p>
                        )}
                </div>
                <button type="submit" className="form-group btn-yellow" disabled={this.state.buttonDisable || this.props.pinClicked}>Set PIN</button>
            </>
        )
    };
}

export default SetPinModule;



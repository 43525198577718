import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignupPage from './components/SignupPage';
import LoginPage from './components/LoginPage';
import ErrorPage from './components/ErrorPage';
import ChangePasswordPage from './components/ChangePasswordPage';
import './css/App.css';

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route path="/changePassword" element={<ChangePasswordPage />} />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;

import { CONFIGS, URI } from './constants';

export function getConfig() {
    if (window.location.href.includes("localhost")) {
        return CONFIGS["alpha"]["us-west-2"];
    }

    const url = new URL(window.location.href);
    const stage = url.host.split('.')[0];
    const stageConfig = CONFIGS?.[stage];
    if (stage !== "prod") {
        return stageConfig?.["us-west-2"];
    }

    const region = url.host.split('.')[1];
    const regionConfig = stageConfig?.[region];
    if (!regionConfig) {
        console.error(`Got invalid configuration: ${stage}-${region}, returning alpha.`);
        return CONFIGS["alpha"]["us-west-2"];
    }

    return regionConfig;
};

let config;
try {
    config = getConfig();
} catch (e) {
    console.error(e);
    config = CONFIGS["alpha"]["us-west-2"];
}
export const signupUiEndpoint = config.UI_ENDPOINT.concat(URI.SIGNUP);
export const signupEndpoint = config.DEVICE_ENDPOINT.concat(URI.SIGNUP);
export const getOtpEndpoint = config.DEVICE_ENDPOINT.concat(URI.GET_OTP);
export const verifyOtpEndpoint = config.DEVICE_ENDPOINT.concat(URI.VERIFY_OTP);
export const changePasswordEndpoint = config.DEVICE_ENDPOINT.concat(URI.CHANGEPASSWORD);
export const loginUiEndpoint = config.UI_ENDPOINT.concat(URI.LOGIN);
export const loginEndpoint = config.DEVICE_ENDPOINT.concat(URI.LOGIN);
export const errorUiEndpoint = config.UI_ENDPOINT.concat(URI.ERROR);
